export const Reklama = ({ item, styles }) => {
    return (
        <div className={styles.reklama}>
            {item && <>
                <div className={styles.items}>{item?.name}</div>
                <div className={styles.items}>{item?.inn}</div>
                <div className={styles.items}>{item?.gov_id}</div>
            </>}
        </div>
    );
};