export const getTotalCost = (price, count = 1, insurance = 0) => {
  let currentCount = count;
  if (count < 0) {
    currentCount = 1;
  }
  return +(price * currentCount + insurance * currentCount).toFixed(2);
};

export const eventGoodsSetter = (eventGoodsObj, eGoods) => {
  if (eGoods.length) {
    const event = eGoods[0]?.event_city[0];
    const newObj = { ...eventGoodsObj, [event]: eGoods };
    return newObj;
  } else {
    const newObj = { ...eventGoodsObj };
    return newObj;
  }
};

// Формируем объект вида: { event: [...formats] } для проверки доступных билетов в корзине
export const ticketFormatsSetter = (
  existingTicketFormats,
  newTicketFormats
) => {
  if (newTicketFormats.length) {
    const event =
      newTicketFormats[0]?.event_public_id + '_' + newTicketFormats[0]?.address;
    const newObj = { ...existingTicketFormats, [event]: newTicketFormats };
    return newObj;
  } else {
    return { ...existingTicketFormats };
  }
};

// VIP user discount price
export const setCostItem = (item, user) => {
  const { price, count } = item;
  const { discount, discount_form, role } = user;
  let cost;

  if (
    role &&
    typeof role !== 'string' &&
    role.find((el) => el === 'vip') &&
    item.selector === 'ticket'
  ) {
    const discountAmount =
      discount_form === 'percent' && discount !== 100 ? discount / 100 : 0;

    const oneTicketVipPrice = price - price * 1 * discountAmount;
    const restTicketsPrice = count === 1 ? 0 : price * (count - 1);

    cost = oneTicketVipPrice + restTicketsPrice;
  } else {
    cost = price * count;
  }
  return cost;
};

// ф-ция счетчик для скидки - используется в useEffect, чтобы
// на момент рендера компонента считать скидку
export const saleCounter = (oneGoods, sale) => {
  let discount = 0;
  if (sale.code_count >= oneGoods.count) {
    discount = oneGoods.price * oneGoods.count * (sale.discount / 100);
  } else {
    discount = oneGoods.price * sale.code_count * (sale.discount / 100);
  }
  return discount < 0 ? 0 : discount;
};

// Получить цену с учетом скидки
export const setDiscountPrice = (price, discount, discount_form) => {
  let discountPrice = 0;
  if (discount_form === 'percent') {
    discountPrice = discount !== 100 ? price - price * 1 * (discount / 100) : 0;
  }
  return discountPrice;
};
