import styles from "./InsurancePopup.module.scss";
import Popup from "../Popup/Popup";
import CustomSelect from "components/Calendar/Select/Select";
import { setOptions } from "mocks/calendarOptions";
import { useEffect, useState } from "react";
import { ReactComponent as CustomArrow } from "images/svg/dropdownIcon.svg";
import { getInsuranceFormats } from "reducer/basket/selectors";
import { useDispatch, useSelector } from "react-redux";
import { Operation } from "reducer/basket/basket";
import { Operation as DataOperation, ActionCreator } from "reducer/data/data";
import { useNavigate } from "react-router-dom";
import { routes } from "const/routes";
import { initDate, nextTwoYear } from "mocks/time";
import { getEventType, getFilteredEventsInsurance } from "reducer/data/selectors";
import "../../Calendar/Select/Select.scss";
import "../../Calendar/Calendar.scss";

const allYearEvents = {
  date_from: initDate,
  date_to: nextTwoYear,
  month: "Все",
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return new Intl.DateTimeFormat("ru-RU", {
    day: "numeric",
    month: "long",
  }).format(date);
};

export const setInsuranceOptions = (arr) => {
  if (arr.length > 0) {
    return arr.map((el) => {
      return { value: el.public_id, label: `${formatDate(el.start_time)} - ${el.address}` };
    });
  }
  return [];
};

export const InsurancePopup = ({ statePopupHandler, insurance }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateTo = () => navigate(routes.basket.path, { replace: true });

  const [city, setCity] = useState("");
  const [activity, setActivity] = useState("");
  const [format, setFormat] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [event, setEvent] = useState(null);
  const [isEvents, setIsEvents] = useState(false);
  const [currentFormat, setCurrentFormat] = useState(null);

  const insuranceFormats = useSelector((state) => getInsuranceFormats(state));
  const filteredEvents = useSelector((state) => getFilteredEventsInsurance(state));
  const eventTypes = useSelector((state) => getEventType(state));

  const handleActivityFilter = (evt, param) => {
    if (param === "city") {
      setCity(evt);
    }
    if (param === "activity") {
      const activity = eventTypes.find((el) => el.title === evt.value);
      setEvent(activity);
      setActivity(evt);
    }
    if (param === "format") {
      const currentFormat = insuranceFormats.find((el) => el.title === evt.value);
      if (currentFormat) {
        setCurrentFormat(currentFormat);
      }
      setFormat(evt);
    }
  };

  const handleCheckChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const submit = () => {
    if (currentFormat.start_time) {

      const sendObj = {
        ...insurance,
        selector: 'insurance',
        totalCost: 1
          ? +(insurance.price * 1).toFixed(2)
          : insurance.price,
        count: 1,
        promocode: null,
        format_public_id: insurance.public_id,
        unique_id: new Date().getTime() + insurance.public_id,
        start_date: currentFormat.start_time
      };

      dispatch(Operation.addGoods(sendObj, navigateTo));
    }

    dispatch(ActionCreator.cleanFormats([]));
    dispatch(ActionCreator.setFilteredEventsInsurance([]));
    setCity("");
    setFormat("");
  };

  useEffect(() => {
    if (city) {
      dispatch(Operation.loadInsuranceFormats(city.value, insurance.public_id));
    }
  }, [city]);

  useEffect(() => {
    dispatch(ActionCreator.cleanFormats([]));
    if (event) {
      dispatch(DataOperation.loadFilteredEventsInsurance({
        city: { id: 0 },
        date_from: allYearEvents.date_from,
        date_to: allYearEvents.date_to,
        event_type: { public_id: event.public_id },
      }, setIsEvents),);
      setCity("");
      setFormat("");
    }
    return () => dispatch(ActionCreator.setFilteredEventsInsurance([]));
  }, [event]);

  useEffect(() => {
    if (eventTypes?.length === 0) dispatch(DataOperation.loadEventType());
  }, [dispatch, eventTypes?.length]);

  return (
    <Popup outsideStyles={styles}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>Страхование участия</h4>
        <div className={styles.selectsWrapper}>

          <div className={styles.filters}>

            <div>
              <p className={styles.description}>Выберите событие</p>
              <CustomSelect
                data-filter={"activity"}
                handleSelectChange={(evt) =>
                  handleActivityFilter(evt, "activity")
                }
                value={activity}
                customArrow={CustomArrow}
                prefix={"select-insurance-activity"}
                name={"activity"}
                options={setOptions(eventTypes)}
                placeholder={"Выберите событие"}
                styles={styles}
                closeMenuOnScroll={() => false}
              />
            </div>
            {/*  {filteredEvents.length && event ? */} <div style={{ pointerEvents: !filteredEvents.length ? "none" : "auto" }} className={styles.city}>
              <p className={styles.description}>Выберите город</p>
              <CustomSelect
                data-filter={"city"}
                handleSelectChange={(evt) => handleActivityFilter(evt, "city")}
                value={city}
                customArrow={CustomArrow}
                prefix={"select-insurance-city"}
                name={"city"}
                options={setInsuranceOptions(filteredEvents)}
                placeholder={"Выберите город"}
                styles={styles}
                closeMenuOnScroll={() => false}
              />
            </div> {/* : null} */}

            {insuranceFormats?.length && filteredEvents.length ? <div className={styles.city}>
              <p className={styles.description}>Выберите формат</p>
              <CustomSelect
                data-filter={"format"}
                handleSelectChange={(evt) => handleActivityFilter(evt, "format")}
                value={format}
                customArrow={CustomArrow}
                prefix={"select-insurance-format"}
                name={"format"}
                options={setOptions(insuranceFormats)}
                placeholder={"Выберите формат"}
                styles={styles}
                closeMenuOnScroll={() => false}
              />
              <div className={styles.firstCheckbox}>
                <input
                  className={"visually-hidden"}
                  id="insurance-legals"
                  type="checkbox"
                  onChange={handleCheckChange}
                  value={isChecked}
                />
                <label
                  className={styles.checkboxLabel}
                  htmlFor="insurance-legals"
                >
                  Соглашаюсь с {" "}
                  <a className={styles.legals} href={insurance.legals}>Условиями страхования</a>
                </label>
              </div>
              <div className={styles.btn}>
                <button disabled={!format.value || !isChecked} onClick={submit} style={{ opacity: !format.value || !isChecked ? 0.5 : 1, pointerEvents: !format.value || !isChecked ? "none" : "auto" }} className={styles.btnInsurance}>Купить</button>
              </div>
            </div>

              : null}
            {insuranceFormats?.length === 0 || isEvents ? <h4 className={styles.text}>Для выбранного события страховка недоступна</h4> : null}
          </div>
          <button
            onClick={statePopupHandler}
            data-state="close"
            className={styles.popupCloseBtn}
            type="button"
            aria-label="Закрыть"
          >
            <span className="visually-hidden">Закрыть</span>
          </button>
        </div>
      </div>
    </Popup>
  );
};
