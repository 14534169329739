import styles from "./OptionalCard.module.scss";
import "./OptionalCard.scss";
import { BASKET_COUNT } from "../../../const/const";
import { useDispatch, useSelector } from "react-redux";
import { getTotalCost } from "utils/goods";
import { useEffect, useRef, useState } from "react";
import { Operation } from "reducer/basket/basket";
import CustomSelect from "../../Calendar/Select/Select";
import { setCountOptions } from "../../../mocks/calendarOptions";
import {
  getAllGoodsInBasket,
  getCurrentRemoveGoods,
} from "reducer/basket/selectors";
import { REACT_APP_API } from "api";
import { Link } from "react-router-dom";
import { routes } from "const/routes";

const OptionalCard = ({ product, id }) => {
  const [errorSize, setErrorSize] = useState(``);
  const [sizeValue, setSizeValue] = useState(null);

  const dispatch = useDispatch();
  const checkboxRef = useRef();
  const handleSizeResetError = () => setErrorSize(``);
  const currentRemoveGoods = useSelector((state) =>
    getCurrentRemoveGoods(state)
  );
  const goods = useSelector((state) => getAllGoodsInBasket(state));

  const getSize = () => {
    if (product.proportions.length === 1) {
      return { value: product.proportions[0]?.name, label: product.proportions[0]?.name };
    } else {
      return null;
    }
  };

  useEffect(() => {

    setSizeValue(getSize());
    goods.forEach((el) => {
      if (checkboxRef.current.id?.includes(el?.public_id)) {
        checkboxRef.current.checked = true;
      }
      /*  if (el.size) {
         setSizeValue(el.size);
       } */
    });
  }, []);

  useEffect(() => {
    if (errorSize) {
      checkboxRef.current.checked = false;
    }
    if (currentRemoveGoods) {
      if (checkboxRef.current.id?.includes(currentRemoveGoods?.public_id)) {
        checkboxRef.current.checked = false;

        setSizeValue(getSize());
      }
    }
  }, [currentRemoveGoods, errorSize, checkboxRef]);
  // Функция, добавления предложенных "продуктов"
  // # КОСТЫЛЬНО
  const basketEventGoodsHandler = () => {
    if (errorSize) {
      checkboxRef.current.checked = false;
    }
    if (!checkboxRef.current.checked) {
      setSizeValue(getSize());
    }
    const value = checkboxRef.current.checked;

    let count = 0;
    let totalCost;
    const selector = "shop";

    if (product.proportions.length > 0) {
      if (value) {
        if (sizeValue) {
          count =
            count >= BASKET_COUNT.max_count
              ? BASKET_COUNT.max_count
              : count + 1;

          totalCost = getTotalCost(
            product.price,
            count,
            product.insurance && product.insurance_cost
          );
          const newProduct = {
            ...product,
            size:
              product.proportions.length > 0 ? sizeValue.value : null,
            promocode: null,
            logo: product.picture,
            totalCost,
            count,
            selector,
          };

          dispatch(Operation.addShop(newProduct));
        } else {
          setErrorSize("Выберите размер");
        }
      } else {
        const count = 0;
        totalCost = getTotalCost(
          product.price,
          count,
          product.insurance && product.insurance_cost
        );

        const newProduct = {
          ...product,
          totalCost,
          count,
        };

        dispatch(Operation.removeShop(newProduct));
      }
    } else {
      if (value) {
        count =
          count >= BASKET_COUNT.max_count ? BASKET_COUNT.max_count : count + 1;

        totalCost = getTotalCost(
          product.price,
          count,
          product.insurance && product.insurance_cost
        );
        const newProduct = {
          ...product,
          size: product.proportions.length > 0 ? sizeValue.value : null,
          promocode: null,
          logo: product.picture,
          totalCost,
          count,
          selector,
        };

        dispatch(Operation.addShop(newProduct));
      } else {
        const count = 0;
        totalCost = getTotalCost(
          product.price,
          count,
          product.insurance && product.insurance_cost
        );

        const newProduct = {
          ...product,
          totalCost,
          count,
        };

        dispatch(Operation.removeShop(newProduct));
      }
    }
  };

  return (
    <div className={styles.optionalCard}>
      <div className={styles.imageWrap}>
        <Link to={`${routes.product.path}/${product.public_id}`}>
          <img className={styles.image} src={`${REACT_APP_API}${product.picture}`} alt="Товар" />
        </Link>
      </div>
      <div className={styles.infoBlock}>
        <div className={styles.insideItem}>
          <div className={styles.content}>
            <b className={styles.title}>{product.title}</b>
            {product.proportions.length > 0 && (
              <CustomSelect
                error={errorSize}
                handleSelectChange={setSizeValue}
                handleFocus={handleSizeResetError}
                value={sizeValue}
                prefix={`optCard-select`}
                styles={styles}
                title={``}
                name={`size`}
                options={setCountOptions(product.proportions)}
                placeholder={
                  /* sizeValue ||  */`Выберите размер`
                }
                closeMenuOnScroll={() => false}
              />
            )}
          </div>
          <div className={styles.count}>
            <div className={styles.checkbox_group}>
              <input
                type="checkbox"
                id={id}
                className="visually-hidden"
                onChange={basketEventGoodsHandler}
                ref={checkboxRef}
              />
              <label className={styles.label} htmlFor={id}>
                Добавить
              </label>
            </div>

            {/* {
              isAddingGood ?
                <div className={`${styles.text} ${styles.countText}`}>
                  <button
                  type="button"
                  data-ticket-btn="increment"
                  className={styles.countBtn}
                  onClick={()=>{}}
                  >+</button>
                  <span>{product.count}</span>
                  <button
                  type="button"
                  data-ticket-btn="decrement"
                  className={styles.countBtn}
                  onClick={()=>{}}
                  >-</button>
              </div>
              : null
            } */}
          </div>
          <div className={styles.costWrap}>
            <p className={styles.cost}>{product.price} &#8381;</p>
          </div>
        </div>
        <p className={`${styles.text} ${styles.description}`}>
          {product.description}
        </p>
      </div>
    </div>
  );
};

export default OptionalCard;
