import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import styles from './Personal.module.scss';
import Similar from 'components/Basket/Similar/Similar';
import Container from 'components/App/Container/Container';
import { PersonalLinks } from 'components/Personal/PersonalLinks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOnScreen } from 'customHooks/useOnScreen';
import { useEffect, useRef } from 'react';
import { Operation as DataOperation } from "../../reducer/data/data";
import { useDispatch, useSelector } from 'react-redux';
import { getEvents } from 'reducer/data/selectors';
import { routes } from 'const/routes';
import { animateScroll as scroll } from 'react-scroll';

export const Personal = ({ PersonalItem }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const elementRef = useRef(null);
    const isOnScreen = useOnScreen(elementRef);

    const events = useSelector((state) => getEvents(state));

    const handleStateChange = (el) => {
        navigate(el.active);
    };
    const dispatch = useDispatch();

    useEffect(() => {
        if (isOnScreen && events.length === 1 && location?.pathname === routes.myEvents.path || location?.pathname === routes.myOrders.path) {
            dispatch(DataOperation.loadEvents());
        }
    }, [isOnScreen]);


    useEffect(() => {
        const element = document.getElementById('personal-item');
        if (element) {
            scroll.scrollTo(element.offsetTop);
        }
    }, [location]);

    return (
        <div className={styles.main}>
            <Header />

            <div className={styles.profile}>
                <PersonalLinks handleStateChange={handleStateChange} />

                <div className={styles.settings} id="personal-item">
                    {PersonalItem}
                </div>
            </div>
            <Container>
                <div ref={elementRef}>
                    <Similar style={styles.similarWrap} isVisible={location?.pathname === routes.myEvents.path || location?.pathname === routes.myOrders.path} />
                </div>
            </Container>

            <Footer />
        </div>
    );
};