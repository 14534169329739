import styles from "../MyInsurance.module.scss";

export const InsuranceCard = ({ item, isFootnote = true, setSelectedInsurance }) => {

    return (<li className={styles.insuranceCard}>
        {item.logo ? <div className={styles.logoInsuranceWrap}>
            <img className={styles.logo} src={item.logo} />
        </div> : null}
        <p className={styles.name}>{item.name}</p>
        <p className={styles.title}>{item.title}</p>
        <p className={styles.subtitle}>{item.subtitle}</p>
        <p className={styles.subtitle}>{item.description}</p>
        {isFootnote && item.footnote ? <p className={styles.footnote}>{item.footnote}</p> : null}
        <div className={styles.foot}>
            {item.price ? <div className={styles.price}>
                <p className={styles.insurancePrice}>Стоимость:</p>
                <p className={styles.number}>{item.price} ₽</p>
            </div> : null}
            <button onClick={() => setSelectedInsurance(item)} className={styles.btnInsurance}>Оформить</button>
            <div className={styles.linkWrap}>
                {item.legals ? <a target={"_blank"} rel="noreferrer" href={item.legals} className={styles.linkInsurance}>Условия страхования</a> : null}
            </div>
        </div>
    </li>);
};