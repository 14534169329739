import { getEventTime } from "utils/date";
import { ReactComponent as TimeLogo } from "images/svg/time.svg";
import { ReactComponent as IconQRCode } from '../../../images/svg/icon-qr-code.svg';
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { routes } from "const/routes";
import { Link } from "react-router-dom";

const TicketEventsCard = ({ item, styles, status, past }) => {

  const userObj = JSON.parse(localStorage.userObj);
  const [isQrClick, setIsQrClick] = useState(false);

  return (
    <li className={styles.item}>

      {isQrClick ?

        <QRCodeSVG
          value={item.public_id}
          className={styles.qrCodeWrapper}
          size={200}
          onClick={() => setIsQrClick(false)}
          bgColor={'#fff'}
          fgColor={'#000'}
          level={'L'}
          includeMargin={false}
        />
        : <div style={{ minHeight: status && "380px" }} className={styles.itemWrapper}>
          <div className={styles.collumnLeft}>
            <div className={styles.logoWrap}>
              <img
                className={styles.logo}
                src={item?.event.event_type?.logo}
                alt={item?.event_format?.title}
              />

            </div>
            {item.original_user_public_id === userObj.public_id && (
              <p className={styles.info}>
                <span>{item.info.email}</span>
              </p>
            )}
            <p className={styles.info}>
              <span className={styles.itemLabel}>Формат:</span>
              {item?.event_format?.title}
            </p>
            <p className={styles.info}>
              <span className={styles.itemLabel}>Начало мероприятия:</span>
              {getEventTime(item?.event_format, `D MMMM / HH:mm`)}
            </p>
            <p className={styles.info}>
              <span className={styles.itemLabel}>Город:</span>
              {item.city.name_ru}
            </p>
            {status ? (
              <>
                {item.info.first_name && item.info.last_name && (
                  <p className={styles.info} style={{ textTransform: "capitalize" }}>
                    <span className={styles.itemLabel}>ФИО:</span>
                    {`${item.info.first_name
                      } ${item.info.last_name}`}
                  </p>
                )}
                {item?.event_format?.insurance_available && (
                  <p className={styles.info}>
                    <span className={styles.itemLabel}>Страховка:</span>
                    {item.info.insurance ? ` Есть` : `Нет`}
                  </p>
                )}
              </>
            ) : null}
          </div>

          {status ? (
            <>
              <p className={`${styles.info} ${styles.infoTicket}`}>
                <span className={styles.itemLabel}>Номер:</span>№&nbsp;
                {item.info.number || `*****`}
                {item.team && (
                  <>
                    <span style={{ marginTop: "5px" }} className={styles.itemLabel}>Команда:</span>№&nbsp;
                    {item?.team?.number}
                    {`${item?.info?.inside_number ? "-" + item?.info?.inside_number : ""
                      }`}
                  </>
                )}
                {item?.info?.sector && <> <span style={{ marginTop: "5px" }} className={styles.itemLabel}>Сектор:</span>{item.info.sector}</>}
                {item?.info?.row && <> <span className={styles.itemLabel}>Ряд:</span>{item.info.row}</>}
                {item?.info?.place && <><span className={styles.itemLabel}>Место:</span>{item.info.place}</>}
                {item?.event_format?.qr && <IconQRCode
                  style={{ left: "-10px", top: item.info.row && item.team ? "170px" : item.info.row && !item.team ? "140px" : item.team ? "70px" : "50px" }}
                  className={styles.qr} onClick={() => setIsQrClick(true)} />}
              </p>
              {item?.event_format?.start_time < Date.now() && item?.info?.offline_registered ? <Link
                to={`${routes.myEvents.path}/ticket/${item.public_id}`}
                className={`${styles.btn} ${styles.btnPart}`}
              >
                <span className={styles.btnRegNoHover}>
                  Использован
                </span>
                <span className={styles.btnRegHover}>Подробнее</span>
              </Link> : <Link
                to={`${routes.myEvents.path}/ticket/${item.public_id}`}
                className={`${styles.btn} ${styles.btnRegistered}`}
              >
                <span className={styles.btnRegNoHover}>
                  Билет зарегистрирован
                </span>
                <span className={styles.btnRegHover}>Подробнее</span>
              </Link>}
            </>
          ) : !past ? (
            <>
              <p className={styles.info} style={{ position: "relative" }}>
                <span className={styles.itemLabel}>Номер билета:</span>№
                {item.ticketNumber || `*****`}
                {item?.info?.sector && <> <span style={{ marginTop: "5px" }} className={styles.itemLabel}>Сектор:</span>{item.info.sector}</>}
                {item?.info?.row && <> <span className={styles.itemLabel}>Ряд:</span>{item.info.row}</>}
                {item?.info?.place && <><span className={styles.itemLabel}>Место:</span>{item.info.place}</>}

                {item?.event_format?.qr && <IconQRCode style={{ top: "140px" }} className={styles.qr} onClick={() => setIsQrClick(true)} />}
              </p>
              {item.status === `processing` ? (
                <p className={styles.btnAwait}>
                  <TimeLogo />
                  <span style={{ marginLeft: `0.75rem` }}>Ожидайте возврат</span>
                </p>
              ) : (
                <Link
                  className={styles.btn}
                  to={`${routes.myEvents.path}/ticket/${item.public_id}`}
                >
                  Зарегистрироваться
                </Link>
              )}
            </>
          ) : (
            <>
              <p className={styles.info}>
                <span className={styles.itemLabel}>Номер билета:</span>№&nbsp;
                {item.info.number || `*****`}
                {item?.info?.sector && <> <span style={{ marginTop: "5px" }} className={styles.itemLabel}>Сектор:</span>{item.info.sector}</>}
                {item?.info?.row && <> <span className={styles.itemLabel}>Ряд:</span>{item.info.row}</>}
                {item?.info?.place && <><span className={styles.itemLabel}>Место:</span>{item.info.place}</>}
              </p>
              {/*   {item?.event_format?.online && item?.event_format?.online ? (
                <Link
                  className={`${styles.btn} ${styles.btnPast}`}
                  to={`${routes.myEvents.path}/ticket/${item.public_id}`}
                >
                  Завершено
                </Link>
              ) : (
                <span className={`${styles.btn} ${styles.btnPast}`}>
                  <span className={styles.btnRegNoHover}>Завершено</span>
                </span>
              )} */}
              <Link
                to={`${routes.myEvents.path}/ticket/${item.public_id}`}
                className={`${styles.btn} ${styles.btnPast}`}
              >
                <span className={styles.btnRegNoHover}>
                  Завершено
                </span>
                <span className={styles.btnRegHover}>Подробнее</span>
              </Link>
            </>
          )}
        </div>}

      <div className={styles.cover}>
        <img
          className={styles.coverImg}
          src={item.event.banners.landing_page.mobile_picture}
          alt={item.event.event_type.title}
        />
      </div>
    </li>
  );
};

export default TicketEventsCard;
