import NameSpace from '../name-space';

const NAME_SPACE = NameSpace.BASKET;

export const getAllGoodsInBasket = (state) => state[NAME_SPACE].goods;
export const getUpdateGoodsInBasket = (state) => state[NAME_SPACE].updateGoods;

export const getCurrentRemoveGoods = (state) =>
  state[NAME_SPACE].currentRemoveGoods;
export const getTicketFormatsInBasket = (state) =>
  state[NAME_SPACE].ticketFormats;
export const getRequiredTicketCountError = (state) =>
  state[NAME_SPACE].requiredTicketCountError;
export const getInsurancesList = (state) => state[NAME_SPACE].insurancesList;
export const getInsurancesListById = (state, unique_id) =>
  state[NAME_SPACE].insurancesList.find((el) => el.unique_id === unique_id);
export const getDelivery = (state) => state[NAME_SPACE].delivery;
export const getInsurance = (state) => state[NAME_SPACE].insurance;
export const getInsurances = (state) => state[NAME_SPACE].insurances;
export const getInsuranceFormats = (state) =>
  state[NAME_SPACE].insuranceFormats;
